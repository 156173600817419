import React, { Component } from "react";
import { Card, Divider, Grid, Header, Icon, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class FairPriceCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h3">
          <Icon name="target" />
          Is it a Great Price ?{" "}
        </Header>
      </Divider>
      <Grid stackable centered>
        <Grid.Column width={4}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>Intrinsic Value </Card.Header>
              <Card.Description>
                <Table unstackable compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        Close to IV or up to 25% above IV is ok
                      </Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.intrinsicValue,
                        Utils.format_none,
                        1,
                        1
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              Intrinsic value is a measure of what an company (Stock) is worth.
              This measure is arrived at by means of an objective calculation or
              complex financial model, rather than using the currently trading
              market price of that asset. In financial analysis , the underlying
              value of a company and its cash flow or net income whichever is
              consistent
              <br />
              <br />
              For some companies like Banks and Real Estate Intrinsic Value does
              not makes sense and Book Value must be used
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={4}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>PEG Ratio</Card.Header>
              <Card.Description>
                <Table unstackable compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Value &lt; 1 is ideal</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.pegRatio,
                        Utils.format_number,
                        1.5,
                        0
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              The price/earnings to growth ratio (PEG ratio) is a stock's
              price/earnings ratio (P/E ratio) divided by its percentage growth
              rate. The resulting number expresses how expensive a stock's price
              is relative to its earnings performance.
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column width={4}>
          <Card raised fluid>
            <Card.Content>
              <Card.Header>Price to Book Value Ratio</Card.Header>
              <Card.Description>
                <Table unstackable compact="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell> BookValue </Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.bookPerShare,
                        Utils.format_none,
                        this.props.stockValues.compProfile.price,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell> PB Ratio</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.compProfile.pbRatio,
                        Utils.format_none,
                        1,
                        0
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              Book value per common share (or, simply book value per share -
              BVPS) is a method to calculate the per-share value of a company
              based on common shareholders' equity in the company.
              <br /> <br />
              PB Value of 1.2 is ideal for Banks
              <br /> <br />
              Should the company dissolve, the book value per common share
              indicates the dollar value remaining for common shareholders after
              all assets are liquidated and all debtors are paid
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    </>
  );
}
