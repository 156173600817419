import React, { Component } from "react";
import { Grid, Header, Popup } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class IndexMap extends Component {
  render() {
    //console.log("In Index Map");

    return (
      <div>
        <Header>
          <h2>{this.props.title}</h2>
        </Header>

        <Grid stackable columns={13}>
          {this.props.indexMap
            ? this.props.indexMap.map((item) => {
                return (
                  <Grid.Column
                    key={item.symbol}
                    style={{
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: Utils.getCellBackGroundColor(
                        item.changesColor
                      ),
                    }}
                    onClick={() =>
                      this.props.onClickSymbolFunction(item.symbol)
                    }
                  >
                    <Popup inverted trigger={<p>{item.symbol}</p>} size="large">
                      <Popup.Header>
                        {Utils.format_currency_usd(item.price)}{" "}
                      </Popup.Header>

                      <Popup.Content>
                        {Utils.format_currency_usd(item.change) + " "}(
                        {item.changesPercentage}%)
                      </Popup.Content>
                    </Popup>
                  </Grid.Column>
                );
              })
            : null}
        </Grid>
      </div>
    );
  }
}
