import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Card,
  Header,
  Table,
  Segment,
  Label,
  Divider,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import Utils from "../util/Utils";
import uuid from "react-uuid";

export default function IPOCalendar(props) {
  const [ipos, setIpos] = useState([]);

  function getURL(symbol) {
    return "https://finviz.com/quote.ashx?t=" + symbol;
  }

  useEffect(() => {
    const prior = new Date().setDate(new Date().getDate() - 10);
    const from = new Date(prior).toISOString().slice(0, 10);

    const future = new Date().setDate(new Date().getDate() + 10);
    const to = new Date(future).toISOString().slice(0, 10);

    const ipoURL = `${Constants.IPO_URL}&from=${from}&to=${to}`;

    axios
      .get(ipoURL)
      .then((response) => {
        setIpos(response.data.ipoCalendar);
      })
      .catch((error) => {
        console.error("Error fetching IPO data:", error);
      });
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      {/* Page Header */}
      <Segment raised style={{ marginBottom: "20px" }}>
        <Header as="h2" textAlign="center" color="blue">
          📈 Recent and Upcoming IPOs
        </Header>
        <Divider />
        <p style={{ textAlign: "center", fontSize: "16px", color: "#666" }}>
          Stay updated on the latest IPOs happening in the market.
        </p>
      </Segment>

      {/* IPO Table */}
      <Card fluid raised>
        <Card.Content>
          <Table celled striped compact>
            {/* Table Header */}
            <Table.Header>
              <Table.Row>
                {[
                  "Date",
                  "Symbol",
                  "Exchange",
                  "Company Name",
                  "Number of Shares",
                  "Initial Price",
                  "Total Money Raising",
                  "Status",
                ].map((header) => (
                  <Table.HeaderCell
                    key={header}
                    style={{
                      backgroundColor: "#A9D0F5",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {header}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            {/* Table Body */}
            <Table.Body>
              {ipos.length > 0 ? (
                ipos.map((ipo) => (
                  <Table.Row
                    key={uuid()}
                    style={{
                      backgroundColor:
                        ipo.status === "priced"
                          ? "#E8F8E8" // Light green for priced IPOs
                          : "#FBF8EF", // Light yellow for others
                    }}
                  >
                    <Table.Cell>{ipo.date}</Table.Cell>
                    <Table.Cell>
                      {ipo.status === "priced" ? (
                        <a
                          href={getURL(ipo.symbol)}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#2185D0", fontWeight: "bold" }}
                        >
                          {ipo.symbol}
                        </a>
                      ) : (
                        ipo.symbol
                      )}
                    </Table.Cell>
                    <Table.Cell>{ipo.exchange}</Table.Cell>
                    <Table.Cell>{ipo.name}</Table.Cell>
                    <Table.Cell>
                      {Utils.format_number(ipo.numberOfShares)}
                    </Table.Cell>
                    <Table.Cell>{ipo.price}</Table.Cell>
                    <Table.Cell>
                      {Utils.format_currency_long(ipo.totalSharesValue)}
                    </Table.Cell>
                    <Table.Cell>
                      <Label
                        color={
                          ipo.status === "priced"
                            ? "green"
                            : ipo.status === "pending"
                            ? "yellow"
                            : "grey"
                        }
                      >
                        {ipo.status.charAt(0).toUpperCase() +
                          ipo.status.slice(1)}
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                // No Data Message
                <Table.Row>
                  <Table.Cell colSpan="8" textAlign="center">
                    No IPOs found for the selected date range.
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    </div>
  );
}
