import React, { Component } from "react";
import uuid from "react-uuid";
import { Grid, Popup } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class SectorUtil extends Component {
  // Render empty cells
  static test = (index) => {
    // alert("index:", index);
  };

  static getEmptyGrids = (len) => {
    let content = [];
    for (let i = 0; i < len; i++) {
      content.push(
        <Grid.Column
          key={uuid()}
          style={{
            backgroundColor: "white", // Updated to white color
          }}
          padded={"horizontally"}
        />
      );
    }
    if (content.length === 0) {
      // console.log("returning empty array");
    }
    return content;
  };

  static getSectorRow = (sector, quotesBySector, onClickFunction) => {
    let len = quotesBySector.length;

    let emptyCells = 0;
    if (len <= 12) {
      emptyCells = 12 - len;
    } else {
      if ((len - 12) % 12 === 0) {
        emptyCells = 0;
      } else {
        emptyCells = 12 - ((len - 12) % 12);
      }
    }

    // console.log("Sector ", sector, " Empty Cells:", emptyCells);

    return (
      <>
        <Grid.Column
          padded={"horizontally"}
          width={3}
          style={{
            backgroundColor: "#495674", // Updated sector header background color
            color: "white",
          }}
          textAlign="left"
        >
          <h4>{sector}</h4>
        </Grid.Column>

        {quotesBySector.map((item, index) => {
          return index > 0 && index % 12 === 0 ? (
            <>
              <Grid.Column
                padded={"horizontally"}
                width={3}
                style={{
                  backgroundColor: "#495674", // Updated style for separator column
                }}
              />
              <Grid.Column
                key={item.symbol}
                style={{
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: Utils.getCellBackGroundColor(
                    item.changesColor
                  ),
                }}
                onClick={() => onClickFunction(item.symbol)}
              >
                <Popup inverted trigger={<p>{item.symbol}</p>}>
                  <Popup.Header>
                    {item.name}
                    <br />
                    {Utils.format_currency_usd(item.price)}{" "}
                  </Popup.Header>

                  <Popup.Content>
                    {Utils.format_currency_usd(item.change) + " "}(
                    {item.changesPercentage}%)
                  </Popup.Content>
                </Popup>
              </Grid.Column>
            </>
          ) : (
            <Grid.Column
              key={item.symbol}
              style={{
                cursor: "pointer",
                color: "white",
                backgroundColor: Utils.getCellBackGroundColor(
                  item.changesColor
                ),
              }}
              onClick={() => onClickFunction(item.symbol)}
            >
              <Popup inverted trigger={<p>{item.symbol}</p>} size="large">
                <Popup.Header>
                  {item.name}
                  <br />
                  {Utils.format_currency_usd(item.price)}{" "}
                </Popup.Header>

                <Popup.Content>
                  {Utils.format_currency_usd(item.change) + " "}(
                  {item.changesPercentage}%)
                </Popup.Content>
              </Popup>
            </Grid.Column>
          );
        })}
        {this.getEmptyGrids(emptyCells)}
      </>
    );
  };
}
