import React from "react";
import { Grid, Card, Image, Table, Popup } from "semantic-ui-react";
import Constants from "../util/Constants";
import Utils from "../util/Utils";

const GainersLosers = ({ gainersLosers, onClickSymbolFunction }) => {
  const renderStockTable = (list, isGainer) => {
    return (
      <Table celled compact="very" basic="very" unstackable>
        <Table.Body>
          {list.map((stock, index) => {
            if (
              !stock ||
              (isGainer && stock.changesPercentage <= 0) ||
              (!isGainer && stock.changesPercentage >= 0)
            ) {
              return null;
            }

            return (
              <Table.Row key={stock.symbol}>
                <Table.Cell>
                  <Popup
                    trigger={
                      <div
                        onClick={() => onClickSymbolFunction(stock.symbol)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          src={`${Constants.IMAGE_URL}${stock.symbol}.jpg`}
                          avatar
                          style={{ marginRight: "0.5em" }}
                        />
                        <span style={{ fontWeight: "bold" }}>
                          {stock.symbol}
                        </span>
                      </div>
                    }
                    content={
                      <>
                        <Popup.Header>{stock.name}</Popup.Header>
                        <Popup.Content>
                          <p>{Utils.format_currency_usd(stock.price)}</p>
                          <p>
                            {Utils.format_currency_usd(stock.change)} (
                            {stock.changesPercentage}%)
                          </p>
                        </Popup.Content>
                      </>
                    }
                  />
                </Table.Cell>
                <Table.Cell textAlign="right">
                  <span
                    style={{
                      color: isGainer ? "#4caf50" : "#f44336",
                      fontWeight: "bold",
                    }}
                  >
                    {Utils.format_number(stock.changesPercentage)}%
                  </span>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  const renderCategory = (name, list, isGainer) => (
    <Card style={{ height: "270px", overflowY: "auto" }}>
      <Card.Header
        textAlign="center"
        style={{
          height: "40px",
          background: isGainer ? "#66bb6a" : "#e57373",
          color: "white",
          padding: "0.5em",
          fontSize: "16px",
        }}
      >
        {name}
      </Card.Header>
      <Card.Content style={{ padding: "0.5em" }}>
        {renderStockTable(list, isGainer)}
      </Card.Content>
    </Card>
  );

  const renderCategoryGroup = (gainersCategories, losersCategories) => (
    <Grid.Row>
      {gainersCategories.map(({ name, list }) => (
        <Grid.Column
          key={name}
          mobile={16}
          tablet={8}
          computer={2}
          style={{ padding: "0.5em" }}
        >
          {renderCategory(name, list, true)}
        </Grid.Column>
      ))}
      {losersCategories.map(({ name, list }) => (
        <Grid.Column
          key={name}
          mobile={16}
          tablet={8}
          computer={2}
          style={{ padding: "0.5em" }}
        >
          {renderCategory(name, list, false)}
        </Grid.Column>
      ))}
    </Grid.Row>
  );

  const gainersCategories = [
    { name: "Dow Top 5 Gainers", list: gainersLosers?.dowGainers },
    { name: "S&P Top 5 Gainers", list: gainersLosers?.spGainers },
    { name: "NASDAQ Top 5 Gainers", list: gainersLosers?.nasdaqGainers },
    { name: "Russell Top 5 Gainers", list: gainersLosers?.russellGainers },
  ];

  const losersCategories = [
    { name: "Dow Top 5 Losers", list: gainersLosers?.dowLosers },
    { name: "S&P Top 5 Losers", list: gainersLosers?.spLosers },
    { name: "NASDAQ Top 5 Losers", list: gainersLosers?.nasdaqLosers },
    { name: "Russell Top 5 Losers", list: gainersLosers?.russellLosers },
  ];

  return (
    <Grid style={{ padding: "1em" }}>
      {gainersLosers && (
        <>{renderCategoryGroup(gainersCategories, losersCategories)}</>
      )}
    </Grid>
  );
};

export default GainersLosers;
