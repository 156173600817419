import React, { Component } from "react";
import { Card, Divider, Grid, Header, Icon, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import ChartUtils from "../charts/ChartUtils";
import "../css/home.css";
import Utils from "../util/Utils";

export default class MoatCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h3">
          <Icon name="angle double up" />
          Does it have a sustainable Competetve Advantage ?
        </Header>
        <Header as="h3">
          (Gross Margin &gt; 50% and Profit Margin &gt; 25% is good)
        </Header>
      </Divider>
      <br />
      <Grid stackable centered>
        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
          <Grid.Column width={3}>
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ChartUtils.renderYoYGrowthCard(
                "Gross Margin YoY",
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.incomeStmtValues,
                  "Gross Margin"
                ),
                Utils.format_percent
              )}
            </div>
          </Grid.Column>
          <Grid.Column width="7">
            <Card fluid raised>
              <Card.Content>
                <Card.Header textAlign="center">
                  Last 5 Years Values
                </Card.Header>
                <Card.Description>
                  <Table
                    unstackable
                    collapsing
                    compact="very"
                    celled
                    style={{ margin: "0 auto" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) => (
                          <Table.HeaderCell>
                            {this.props.stockValues.incomeStmtValues[index][
                              "Gross Margin"
                            ].year.substring(0, 4)}
                          </Table.HeaderCell>
                        ))}
                        <Table.HeaderCell>5 Year Trend</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Gross Margin</Table.Cell>

                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Gross Margin"
                            ].value,
                            Utils.format_percent,
                            0.5,
                            1
                          )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.incomeStmtValues,
                            "Gross Margin"
                          ),
                          "Value"
                        )}
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>YoY Growth</Table.Cell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Gross Margin"
                            ].yoyGrowth,
                            Utils.format_percent,
                            0,
                            1
                          )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.incomeStmtValues,
                            "Gross Margin"
                          ),
                          "Growth"
                        )}
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>Profit Margin</Table.Cell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Profit Margin"
                            ].value,
                            Utils.format_percent,
                            0.25,
                            1
                          )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.incomeStmtValues,
                            "Profit Margin"
                          ),
                          "Value"
                        )}
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>YoY Growth</Table.Cell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Profit Margin"
                            ].yoyGrowth,
                            Utils.format_percent,
                            0,
                            1
                          )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.incomeStmtValues,
                            "Profit Margin"
                          ),
                          "Growth"
                        )}
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                The term economic moat, refers to a business' ability to
                maintain competitive advantages over its competitors in order to
                protect its long-term profits and market share from competing
                firms. Just like a medieval castle, the moat serves to protect
                those inside the fortress and their riches from outsiders.
                <br /> <br />
                In general, A company that has Cost advantage, Size advantage,
                High Switching Costs, Networking Effect has a Wide Economic
                Moat.
                <br /> <br />
                Looking at a company's historical Gross Margin and Profit Margin
                tells us if the company was able to keep its stand against the
                competetors.
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={3}>
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ChartUtils.renderYoYGrowthCard(
                "Profit Margin YoY",
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.incomeStmtValues,
                  "Profit Margin"
                ),
                Utils.format_percent
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
