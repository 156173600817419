import React, { Component } from "react";
import { Card, Divider, Grid, Header, Icon, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import ChartUtils from "../charts/ChartUtils";
import "../css/home.css";
import Utils from "../util/Utils";

export default class SalesGrowthCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h3">
          <Icon size="large" name="chart line" />
          Is Revenue positive and growing consistently ?
        </Header>
        <Header as="h3">(10 to 15% growth is ideal)</Header>
      </Divider>
      <br />
      <Grid stackable centered>
        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
          <Grid.Column width="3">
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ChartUtils.renderGrowthCard(
                "Revenue",
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.incomeStmtValues,
                  "Revenue"
                ),
                Utils.format_number
              )}
            </div>
          </Grid.Column>
          <Grid.Column width="7">
            <Card fluid raised>
              <Card.Content>
                <Card.Header textAlign="center">
                  Last 5 Years Values
                </Card.Header>
                <Card.Description>
                  <Table
                    unstackable
                    compact="very"
                    celled
                    collapsing
                    style={{ margin: "0 auto" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) => (
                          <Table.HeaderCell
                            key={
                              this.props.stockValues.incomeStmtValues[index][
                                "Revenue"
                              ].year
                            }
                          >
                            {this.props.stockValues.incomeStmtValues[index][
                              "Revenue"
                            ].year.substring(0, 4)}
                          </Table.HeaderCell>
                        ))}
                        <Table.HeaderCell>5 Year Trend</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Revenue</Table.Cell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Revenue"
                            ].value,
                            Utils.format_currency_short,
                            10,
                            1
                          )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.incomeStmtValues,
                            "Revenue"
                          ),
                          "Value"
                        )}
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>YoY Growth</Table.Cell>
                        {Object.keys(
                          this.props.stockValues.incomeStmtValues
                        ).map((index) =>
                          Utils.TableCell(
                            this.props.stockValues.incomeStmtValues[index][
                              "Revenue"
                            ].yoyGrowth,
                            Utils.format_percent,
                            0.1,
                            1
                          )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.incomeStmtValues,
                            "Revenue"
                          ),
                          "Growth"
                        )}
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                An increasing revenue is the sign of a good company. It denotes
                that the demand for the company’s products or services has
                increased. Conversly, a decreasing or stagnant revenue is a bad
                sign.
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width="3">
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ChartUtils.renderObservations(
                this.props.title,
                this.props.stockValues.revenueObservations
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
  static getMenuColor() {
    return ChartUtils.findObservation(
      "5 Yr Growth",
      this.props.stockValues.revenueObservations
    ).rgIndicator;
  }
}
