import axios from "axios";
import React, { Component, createContext } from "react";
import ReactDOM from "react-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import {
  Button,
  Image,
  Card,
  Container,
  Segment,
  Header,
} from "semantic-ui-react";
import "../css/home.css";
import "../css/index.css";
import Constants from "../util/Constants";
import Footer from "./Footer";
import Home from "./Home";
import SubmitFeature from "./SubmitFeature";
import jwtDecode from "jwt-decode"; // Use the default export
import MainNews from "./MainNews";

export const UserContext = createContext();

export default class MainHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMain: true,
      showAccessRequest: false,
      screenToRender: "",
    };
  }

  handleGoogleSignin = async (googleUser) => {
    const decodedCreds = jwtDecode(googleUser.credential);
    const userName = decodedCreds.name;
    const email = decodedCreds.email;

    try {
      const response = await axios.get(
        `${Constants.VALIDATE_URL}${email}?name=${userName}`
      );
      if (response.data.user) {
        ReactDOM.render(
          <UserContext.Provider value={response.data.user.email}>
            <Home
              userName={response.data.user.name.split(" ")[0]}
              userEmail={response.data.user.email}
              newUser={response.data.user.newUser}
            />
          </UserContext.Provider>,
          document.getElementById("root")
        );
      } else {
        this.setState({ showAccessRequest: true, showMain: false });
        this.setState({
          screenToRender: (
            <SubmitFeature
              userName={userName}
              email={email}
              contactURL={Constants.ACCESS_REQUEST_URL}
              header="It appears you are not setup in the website yet. Don't feel bad. Fill out the form to request access."
            />
          ),
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  handleTempSignin = () => {
    ReactDOM.render(
      <Home userName="SimplyStock" />,
      document.getElementById("root")
    );
  };

  render() {
    const tempSigninButton = window.location.hostname === "localhost" && (
      <Button
        size="large"
        className="ui blue button"
        onClick={this.handleTempSignin}
      >
        Temp Login
      </Button>
    );

    return (
      <GoogleOAuthProvider clientId="17726506230-ilk8h2deqvko7emhb7dsiobe4g2guvjn.apps.googleusercontent.com">
        <Container fluid style={{ padding: 0 }}>
          <Segment
            inverted
            style={{
              borderRadius: 0,
              padding: "1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Image
              size="small"
              src={`${process.env.PUBLIC_URL}/images/simply.PNG`}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              {tempSigninButton}
              <GoogleLogin
                type="standard"
                theme="outline"
                size="medium"
                text="signin_with"
                onSuccess={this.handleGoogleSignin}
                onFailure={() => console.log("Login Failed")}
                useOneTap
                cookiePolicy={"single_host_origin"}
                style={{ marginLeft: "1rem" }}
              />
            </div>
          </Segment>

          <Card fluid style={{}}>
            <Card.Content textAlign="center">
              <Header as="h3" color="blue">
                Stock analysis done faster, easier and simpler!
              </Header>
              <p style={{ color: "#1569C7" }}>
                Screen thousands of stocks, find curated ideas, compare stocks,
                and many more without hours of research.
              </p>
            </Card.Content>
          </Card>

          <MainNews />

          <Segment
            style={{
              borderRadius: 0,
              marginTop: "2rem",
            }}
          >
            <Footer />
          </Segment>
        </Container>
      </GoogleOAuthProvider>
    );
  }
}
