import React from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import withWindowSize from "react-window-size";
import PropTypes from "prop-types";
import "../css/home.css";

const styles = {
  parent: {
    fontSize: "1px",
    color: "white",
  },
  link: {
    textDecoration: "line-trough",
  },
  span: {
    color: "white",
  },
};

const TradingViewChart = (props) => {
  return (
    <AdvancedRealTimeChart
      range="1D"
      symbol={props.symbol}
      autosize={true}
      height={"100%"} // Dynamic height based on window size
      width={"100%"} // Dynamic width based on window size
      hide_legend={false}
      hide_side_toolbar={true}
      hide_top_toolbar={true}
      hide_ideas={true}
      disabled_features={["use_localstorage_for_settings"]}
      trendLineColor="rgba(101, 101, 101, 1)"
      copyrightStyles={styles}
      largeChartUrl="" // Leave as an empty string if not needed
    />
  );
};

// Prop validation
TradingViewChart.propTypes = {
  symbol: PropTypes.string.isRequired,
};

export default TradingViewChart;
