import React, { useState } from "react";
import {
  Button,
  Card,
  Header,
  Image,
  Modal,
  Segment,
  Grid,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockRadarChart from "./StockRadarChart";
import CompanyPage from "../company/CompanyPage";

export default function SnapShots(props) {
  const sr = props.stockReports
    ? !props.displayAll
      ? props.stockReports.slice(0, 50)
      : props.stockReports
    : null;

  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
    loading: true,
  });

  const showModal = (symbol) => {
    setstateValues({ ...stateValues, modalSymbol: symbol, open: true });
  };

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  return (
    <>
      <div
        id="favorites"
        style={{
          minHeight: "74vh",
          marginBottom: "2%",
          padding: "1rem",
        }}
      >
        {!props.displayAll ? (
          <Header as="h4" textAlign="center" style={{ marginBottom: "1rem" }}>
            Note: Only top 50 are shown below
          </Header>
        ) : null}

        <Grid stackable doubling columns={5}>
          {sr &&
            sr.map((favorite) => (
              <Grid.Column
                key={favorite.symbol}
                style={{ marginBottom: "1rem" }}
              >
                <Card
                  raised
                  fluid
                  style={{
                    backgroundColor: "#203B5F",
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                  }}
                  onClick={() => showModal(favorite.symbol)}
                >
                  <Card.Content>
                    <Image
                      floated="left"
                      size="mini"
                      src={favorite.image}
                      rounded
                    />
                    <Card.Header
                      style={{
                        textAlign: "right",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {favorite.symbol}
                    </Card.Header>
                    <Card.Meta
                      style={{
                        textAlign: "right",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      ${favorite.price}
                    </Card.Meta>
                  </Card.Content>
                  <Card.Description>
                    <Segment
                      basic
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "150px",
                      }}
                    >
                      <StockRadarChart
                        scoreData={favorite}
                        width={180} // Adjusted width for better visibility
                        height={140} // Adjusted height for better fit
                        labelSize={"short"}
                      />
                    </Segment>
                  </Card.Description>
                </Card>
              </Grid.Column>
            ))}
        </Grid>
      </div>

      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.modalSymbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="ui blue button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
