import React, { Component } from "react";
import StockChart from "./StockChart";
import {
  Accordion,
  Button,
  Flag,
  Grid,
  Header,
  Icon,
  Image,
  Modal,
  Table,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockRadarChart from "../main/StockRadarChart";
import Constants from "../util/Constants";
import Utils from "../util/Utils";
import CompanyNews from "./CompanyNews";
import CompanyPage from "./CompanyPage";
import CompanyRecommendations from "./CompanyRecommendations";
import StockGrade from "./StockGrade";
import Card from "@mui/material/Card";

export default class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      yahoo: "https://finance.yahoo.com/quote/" + props.stockValues.symbol,
      marketwatch:
        "https://www.marketwatch.com/investing/stock/" +
        props.stockValues.symbol,
      google:
        "https://www.google.com/finance/quote/" +
        props.stockValues.symbol +
        ":" +
        props.stockValues.compProfile.exchangeShortName,
      finviz: "https://finviz.com/quote.ashx?t=" + props.stockValues.symbol,
      holders:
        "https://finance.yahoo.com/quote/" +
        props.stockValues.symbol +
        "/holders",
      tableHeight: "560px", // Set a default height for all tables
    };
    //console.log("CompanyProfile:*********", this.props);
  }

  setOpen = (flag) => {
    this.setState({
      open: flag,
    });
  };

  showModal = (symbol) => {
    this.setState({
      modalSymbol: symbol,
      open: true,
    });
  };

  render = () => {
    //console.log("In Compnayprofile render ", this.props.stockValues.symbol);

    const panels = [
      {
        key: "desc",
        title: this.props.stockValues.compProfile.description
          ? this.props.stockValues.compProfile.description.substring(
              0,
              this.props.stockValues.compProfile.description.indexOf(" ", 400)
            ) + "                            ...{click to expand}"
          : "No Description Available",
        content: this.props.stockValues.compProfile.description
          ? this.props.stockValues.compProfile.description.substring(
              this.props.stockValues.compProfile.description.indexOf(" ", 400)
            )
          : "No Description Available",
      },
    ];

    return (
      <>
        <Grid>
          <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
            <Grid.Column width={16}>
              <div
                style={{
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Accordion
                  styled
                  fluid
                  panels={panels}
                  style={{
                    fontSize: "100%",
                    width: "100%",
                  }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row
            columns={{ mobile: 1, tablet: 2, computer: 4 }}
            style={{
              marginTop: "-1.5%",
            }}
          >
            <Grid.Column width={16}>
              <Table compact="very" stackable>
                <Table.Row verticalAlign="top">
                  <Table.Cell>
                    <Table basic="very" collapsing unstackable>
                      <Table.Row>
                        <Table.Cell>
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Card>
                              <Table compact="very">
                                <Table.Header>
                                  <Table.Row>
                                    <Table.Cell
                                      colSpan="2"
                                      style={{
                                        backgroundColor: "#E0E0E0",
                                      }}
                                    >
                                      <Header as="h4" textAlign="center">
                                        <Icon name="shopping bag" siz="mini" />
                                        SimplyStock SnapShot &copy;{" "}
                                      </Header>
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell>
                                      <div
                                        basic
                                        textAlign="center"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          height: "100%",
                                        }}
                                      >
                                        <StockRadarChart
                                          scoreData={
                                            this.props.stockValues.stockReport
                                          }
                                          width={280}
                                          height={185}
                                          labelSize={"long"}
                                        />
                                      </div>
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            </Card>
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "auto",
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Card>
                              <CompanyRecommendations
                                width={280}
                                height={185}
                                symbol={this.props.stockValues.symbol}
                              />
                            </Card>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell colSpan="2">
                          <StockChart symbol={this.props.stockValues.symbol} />
                        </Table.Cell>
                      </Table.Row>
                    </Table>
                  </Table.Cell>
                  <Table.Cell>
                    <Card>
                      <Table
                        celled
                        unstackable
                        id="Key"
                        style={{
                          height: this.state.tableHeight,
                          overflowY: "auto",
                        }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="info circle" />
                                Key Information
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Country</Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.country}{" "}
                              <Flag
                                name={
                                  this.props.stockValues.compProfile.countryCode
                                    ? this.props.stockValues.compProfile.countryCode.toLowerCase()
                                    : "us"
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>CEO</Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.ceo}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Employees</Table.Cell>
                            <Table.Cell>
                              {Utils.format_number(
                                this.props.stockValues.compProfile
                                  .fullTimeEmployees
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Public since</Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.ipoDate
                                ? this.props.stockValues.compProfile.ipoDate.slice(
                                    0,
                                    10
                                  )
                                : null}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Exchange</Table.Cell>
                            <Table.Cell>
                              {
                                this.props.stockValues.compProfile
                                  .exchangeShortName
                              }
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Index</Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.index}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Shares Outstanding</Table.Cell>
                            <Table.Cell>
                              {Utils.format_number(
                                this.props.stockValues.compProfile
                                  .sharesOutstanding
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Market Cap</Table.Cell>
                            <Table.Cell>
                              {Utils.getCompanySize(
                                this.props.stockValues.compProfile.marketCap
                              )}{" "}
                              (
                              {Utils.format_currency_short(
                                this.props.stockValues.compProfile.marketCap
                              )}
                              )
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Beta</Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.beta}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Institution Ownership</Table.Cell>
                            <Table.Cell>
                              <a
                                title="holders"
                                href={this.state.holders}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {
                                  this.props.stockValues.compProfile
                                    .instOwnership
                                }
                                %
                              </a>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Insider Ownership</Table.Cell>
                            <Table.Cell>
                              {
                                this.props.stockValues.compProfile
                                  .insiderOwnerhip
                              }
                              %
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Average Volume</Table.Cell>
                            <Table.Cell>
                              {Utils.format_number(
                                this.props.stockValues.compProfile.avgVolume
                              )}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Sales (ttm)</Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.sales}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Earnigs (ttm)</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.income,
                              Utils.format_none,
                              0,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Earnigs Date</Table.Cell>
                            {this.props.stockValues.quote
                              .earningsAnnouncement ? (
                              Utils.TableCell(
                                this.props.stockValues.quote.earningsAnnouncement.slice(
                                  0,
                                  10
                                ),
                                Utils.format_none,
                                0,
                                1
                              )
                            ) : (
                              <Table.Cell />
                            )}
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card>
                  </Table.Cell>
                  <Table.Cell>
                    <Card fluid>
                      <Table
                        celled
                        unstackable
                        id="growth"
                        style={{
                          height: this.state.tableHeight,
                          overflowY: "auto",
                        }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="info circle" />
                                Growth{" "}
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Sales (5Y) </Table.Cell>

                            {this.props.stockValues.notEnoughData ? (
                              <Table.Cell
                                style={{
                                  backgroundColor: "#FBF8EF",
                                }}
                              >
                                -{" "}
                              </Table.Cell>
                            ) : (
                              Utils.TableCell(
                                this.props.stockValues.stockReport.salesGrowth,
                                Utils.format_number,
                                10,
                                1,
                                "%"
                              )
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Earnings (5Y) </Table.Cell>
                            {this.props.stockValues.notEnoughData ? (
                              <Table.Cell
                                style={{
                                  backgroundColor: "#FBF8EF",
                                }}
                              >
                                -{" "}
                              </Table.Cell>
                            ) : (
                              Utils.TableCell(
                                this.props.stockValues.stockReport
                                  .netIncomeGrowth / 100,
                                Utils.format_percent,
                                0.1,
                                1
                              )
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell> Cashflow (5Y)</Table.Cell>
                            {this.props.stockValues.notEnoughData ? (
                              <Table.Cell
                                style={{
                                  backgroundColor: "#FBF8EF",
                                }}
                              >
                                -{" "}
                              </Table.Cell>
                            ) : (
                              Utils.TableCell(
                                this.props.stockValues.stockReport
                                  .cashflowGrowth,
                                Utils.format_number,
                                10,
                                1,
                                "%"
                              )
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>
                              <Icon name="external alternate" />
                              Future EPS
                            </Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.ePSnext5Y,
                              Utils.format_none,
                              10,
                              1,
                              "%"
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="info circle" />
                                Valuation{" "}
                              </Header>
                            </Table.Cell>
                          </Table.Row>{" "}
                          <Table.Row>
                            <Table.Cell>Earnigs Per Share (EPS)</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.eps,
                              Utils.format_currency_usd,
                              0,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>PE</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.pe,
                              Utils.format_number,
                              15,
                              0
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>PEG </Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.pegRatio,
                              Utils.format_number,
                              1.5,
                              0
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>P/S </Table.Cell>
                            <Table.Cell>
                              {this.props.stockValues.compProfile.psRatio}
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Book Value</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.bookPerShare,
                              Utils.format_currency_usd,
                              this.props.stockValues.compProfile.price,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>P/B </Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.pbRatio,
                              Utils.format_none,
                              1,
                              0
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="money" />
                                Dividend
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Dividend</Table.Cell>
                            <Table.Cell>
                              <Header as="h4" textAlign="center">
                                {" "}
                                {Utils.format_currency_usd(
                                  this.props.stockValues.compProfile.lastDiv
                                )}
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Dividend Yield</Table.Cell>
                            <Table.Cell>
                              <Header as="h4" textAlign="center">
                                {
                                  this.props.stockValues.compProfile
                                    .dividendYield
                                }
                                %
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card>
                  </Table.Cell>
                  <Table.Cell>
                    <Card fluid>
                      <Table
                        compact="very"
                        celled
                        unstackable
                        id="perf"
                        style={{
                          height: this.state.tableHeight,
                          overflowY: "auto",
                        }}
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="sliders" />
                                Stock Performance
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>1 Day</Table.Cell>

                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfDay,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>1 Week</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfWeek,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>1 Month </Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfMonth,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>YTD</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfYTD,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>1 Year </Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfYear,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>5 Year</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfYTD5,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>10 Year</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.stockReport.perfYTD10,
                              Utils.format_ratio,
                              0,
                              1
                            )}
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="bar chart" />
                                Performance
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>ROE</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.roe,
                              Utils.format_none,
                              12,
                              1,
                              "%"
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Gross Margin</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.grossMargin,
                              Utils.format_none,
                              50,
                              1,
                              "%"
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Profit Margin</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.profitMargin,
                              Utils.format_none,
                              25,
                              1,
                              "%"
                            )}
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell
                              colSpan="2"
                              style={{
                                backgroundColor: "#E0E0E0",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                <Icon name="heart" />
                                Health
                              </Header>
                            </Table.Cell>
                          </Table.Row>

                          <Table.Row>
                            <Table.Cell>Current Ratio</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.currentRatio,
                              Utils.format_none,
                              1,
                              1
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Debt/Equity</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile.debtEq,
                              Utils.format_none,
                              1,
                              0
                            )}
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell>Debt Servicing Ratio</Table.Cell>
                            {Utils.TableCell(
                              this.props.stockValues.compProfile
                                .debtServicingRatio,
                              Utils.format_ratio,
                              30,
                              0
                            )}
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card>
                  </Table.Cell>
                  <Table.Cell>
                    <Card fluid>
                      {this.props.stockValues.etfHoldings !== null ? (
                        <Table
                          singleLine
                          compact="very"
                          celled
                          id="etf"
                          style={{
                            height: this.state.tableHeight,
                            overflowY: "auto",
                          }}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.Cell
                                colSpan="2"
                                style={{
                                  backgroundColor: "#E0E0E0",
                                }}
                              >
                                <Header as="h4" textAlign="center">
                                  <Icon name="shopping bag" siz="small" />
                                  ETF exposure
                                </Header>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {Object.keys(
                              this.props.stockValues.etfHoldings.slice(0, 15)
                            ).map((index) => (
                              <Table.Row
                                key={
                                  this.props.stockValues.etfHoldings[index].etf
                                }
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.showModal(
                                    this.props.stockValues.etfHoldings[index]
                                      .etf,
                                    true
                                  );
                                }}
                              >
                                <Table.Cell>
                                  <Header as="h6" image>
                                    <Image
                                      src={
                                        Constants.IMAGE_URL +
                                        this.props.stockValues.etfHoldings[
                                          index
                                        ].etf +
                                        ".jpg"
                                      }
                                      rounded
                                      style={{ mixBlendMode: "multiply" }}
                                    />
                                  </Header>
                                  {
                                    this.props.stockValues.etfHoldings[index]
                                      .etf
                                  }
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {this.props.stockValues.etfHoldings[index]
                                    .weightPercentage === 10000
                                    ? "N/A"
                                    : this.props.stockValues.etfHoldings[index]
                                        .weightPercentage + "%"}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      ) : (
                        <></>
                      )}
                    </Card>
                  </Table.Cell>
                </Table.Row>
                <Table.Row verticalAlign="top">
                  <Table.Cell>
                    <Card fluid>
                      <StockGrade symbol={this.props.stockValues.symbol} />
                    </Card>
                  </Table.Cell>
                  <Table.Cell colSpan="4">
                    <Card style={{ border: "none", boxShadow: "none" }}>
                      <CompanyNews
                        symbol={this.props.stockValues.symbol}
                        companyName={
                          this.props.stockValues.compProfile.companyName
                        }
                      />
                    </Card>
                  </Table.Cell>
                </Table.Row>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Modal
          closeOnEscape={true}
          centered={true}
          open={this.state.open}
          size="fullscreen"
        >
          <Modal.Content scrolling>
            <Modal.Description>
              <CompanyPage symbol={this.state.modalSymbol} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="ui blue button"
              onClick={() => this.setOpen(false)}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  };
}
