import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import {
  Header,
  Image,
  List,
  Table,
  Card,
  Modal,
  Button,
  Popup,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import uuid from "react-uuid";
import { UserContext } from "../main/MainHome.js";
import CompanyPage from "../company/CompanyPage";
import Utils from "../util/Utils";

export default function EarningsCalendar() {
  const [stateValues, setstateValues] = useState({
    open: false,
    modalSymbol: "",
    loading: true,
  });

  const showModal = (symbol) => {
    setstateValues({ ...stateValues, modalSymbol: symbol, open: true });
  };

  const setOpen = (flag) => {
    setstateValues({ ...stateValues, open: flag });
  };

  const getEarningsList = (earnigsForDay, time) => {
    return (
      <List divided size="medium">
        {earnigsForDay &&
          earnigsForDay.map((item) =>
            item.hour === time ? (
              <List.Item
                key={uuid()}
                onClick={() => showModal(item.symbol)}
                style={{
                  cursor: "pointer",
                }}
              >
                <Image
                  size="mini"
                  src={item.imageUrl}
                  style={{
                    mixBlendMode: "multiply",
                  }}
                />
                <List.Content>
                  <List.Header>
                    <Popup inverted trigger={<p>{item.symbol}</p>} size="large">
                      <Popup.Header>
                        <Header as="h6" image>
                          <Image
                            src={Constants.IMAGE_URL + item.symbol + ".jpg"}
                            rounded
                          />
                        </Header>
                        {item.symbol}
                      </Popup.Header>
                      <Popup.Content>
                        EPS Estimate:{" "}
                        {Utils.format_currency_usd(item.epsEstimate)}
                      </Popup.Content>
                    </Popup>
                  </List.Header>
                  {item.companyName.split(" ")[0]}
                </List.Content>
              </List.Item>
            ) : null
          )}
      </List>
    );
  };

  const userEmail = useContext(UserContext);

  const [earningsByDate, setEarningsByDate] = useState([]);

  useEffect(() => {
    var urlVal = Constants.EARNINGS_CAL_URL + "?userEmail=" + userEmail;
    axios({
      method: "get",
      responseType: "json",
      url: urlVal,
    })
      .then((response) => {
        setEarningsByDate(response.data.earningsByDate);
      })
      .catch(function (error) {});
  }, []);

  return (
    <>
      <div
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          padding: "15px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Card fluid raised style={{ boxShadow: "0 0 10px rgba(0,0,0,0.1)" }}>
          <Card.Content>
            <Card.Description>
              <Header
                color="blue"
                textAlign="center"
                as="h2"
                style={{ fontSize: "22px" }}
              >
                Earnings Calendar{" "}
              </Header>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>

      <div
        style={{
          marginTop: "0.3%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
      >
        <Header as="h5" textAlign="right">
          Only Top 10 Large companies are shown for each session.
        </Header>
        <Card fluid raised>
          <Card.Content>
            <Card.Description>
              <Table celled compact>
                <Table.Header>
                  <Table.Row>
                    {earningsByDate
                      ? Object.keys(earningsByDate).map((key, i) => (
                          <Table.HeaderCell
                            key={uuid()}
                            colSpan="2"
                            style={{
                              backgroundColor: "#A9D0F5",
                            }}
                          >
                            <Header as="h2" textAlign="center">
                              {key}
                            </Header>
                          </Table.HeaderCell>
                        ))
                      : null}
                  </Table.Row>
                  <Table.Row>
                    {earningsByDate
                      ? Object.keys(earningsByDate).map((key, i) => (
                          <React.Fragment key={uuid()}>
                            <Table.HeaderCell
                              key={uuid()}
                              singleLine
                              style={{
                                backgroundColor: "#A9D0F5", //CEE3F6",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                Before Market Open
                              </Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              key={uuid()}
                              singleLine
                              style={{
                                backgroundColor: "#A9D0F5",
                              }}
                            >
                              <Header as="h4" textAlign="center">
                                After Market Close
                              </Header>
                            </Table.HeaderCell>
                          </React.Fragment>
                        ))
                      : null}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  <Table.Row key={uuid()}>
                    {earningsByDate
                      ? Object.keys(earningsByDate).map((key, i) => (
                          <React.Fragment key={uuid()}>
                            <Table.Cell
                              key={uuid()}
                              verticalAlign="top"
                              style={{
                                backgroundColor: "#FBF8EF",
                              }}
                            >
                              {getEarningsList(earningsByDate[key], "bmo")}
                            </Table.Cell>
                            <Table.Cell
                              key={uuid()}
                              verticalAlign="top"
                              style={{
                                backgroundColor: "#FBF8EF",
                              }}
                            >
                              {getEarningsList(earningsByDate[key], "amc")}
                            </Table.Cell>
                          </React.Fragment>
                        ))
                      : null}
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Card>
      </div>
      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="fullscreen"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.modalSymbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button className="ui blue button" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
