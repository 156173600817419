import React, { Component } from "react";
import { Card, Divider, Grid, Header, Table } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import ChartUtils from "../charts/ChartUtils";
import "../css/home.css";
import Utils from "../util/Utils";

export default class CashflowGrowthCheck extends Component {
  state = {};

  render = () => (
    <>
      <Divider horizontal>
        <Header as="h4">
          Is Operating Cash Flow positive and growing consistently ?
        </Header>
        <Header as="h3">(10 to 15% growth is ideal)</Header>
      </Divider>
      <br />
      <Grid stackable centered>
        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
          <Grid.Column width={3}>
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ChartUtils.renderGrowthCard(
                "Operating Cashflow",
                ChartUtils.getXyDataFromValues(
                  this.props.stockValues.cfStmtValues,
                  "Operating Cashflow"
                ),
                Utils.format_number
              )}
            </div>
          </Grid.Column>
          <Grid.Column width="7">
            <Card fluid raised>
              <Card.Content>
                <Card.Header textAlign="center">
                  Last 5 Years Values
                </Card.Header>
                <Card.Description>
                  <Table
                    unstackable
                    collapsing
                    compact="very"
                    celled
                    style={{ margin: "0 auto" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        {Object.keys(this.props.stockValues.cfStmtValues).map(
                          (index) => (
                            <Table.HeaderCell
                              key={
                                this.props.stockValues.cfStmtValues[index][
                                  "Operating Cashflow"
                                ].year
                              }
                            >
                              {this.props.stockValues.cfStmtValues[index][
                                "Operating Cashflow"
                              ].year.substring(0, 4)}
                            </Table.HeaderCell>
                          )
                        )}
                        <Table.HeaderCell>5 Year Trend</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Cashflow</Table.Cell>
                        {Object.keys(this.props.stockValues.cfStmtValues).map(
                          (index) =>
                            Utils.TableCell(
                              this.props.stockValues.cfStmtValues[index][
                                "Operating Cashflow"
                              ].value,
                              Utils.format_currency_short,
                              10,
                              1
                            )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.cfStmtValues,
                            "Operating Cashflow"
                          ),
                          "Value"
                        )}
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>YoY Growth</Table.Cell>
                        {Object.keys(this.props.stockValues.cfStmtValues).map(
                          (index) =>
                            Utils.TableCell(
                              this.props.stockValues.cfStmtValues[index][
                                "Operating Cashflow"
                              ].yoyGrowth,
                              Utils.format_percent,
                              0.1,
                              1
                            )
                        )}
                        {ChartUtils.renderTinyChart(
                          ChartUtils.getXyDataFromValues(
                            this.props.stockValues.cfStmtValues,
                            "Operating Cashflow"
                          ),
                          "Growth"
                        )}
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                Operating Cashflow is the amount of cash generated by the
                company’s general operations. For an ideal company, the
                operating cash flow would normally be higher than the net income
                and would tend to be parallel to the net income unless in case
                of a huge shift in company’s strategy which you should
                investigate about.
                <br />
                <br />A huge deviation between income and operating cash flow
                depicts the possibility of accounting manipulation. Operating
                Cashflow is a very important metric when you want to analyse
                about how reliable a company’s profit figures are.
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column width={3}>
            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ChartUtils.renderObservations(
                this.props.title,
                this.props.stockValues.operatingCashObservations
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
