import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, CardGroup, CardContent } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";
import ReactTimeAgo from "react-time-ago";

export default function CompanyNews(props) {
  const [stateValues, setStateValues] = useState({
    news: [],
    bullishPct: 0,
    bearishPct: 0,
  });

  useEffect(() => {
    console.log("CompanyNews useEffect ", props);

    const newsURL = `${Constants.NEWS_URL}&tickers=${props.symbol}`;
    console.log("newsURL:", newsURL);

    const fetchData = async () => {
      try {
        const newsResponse = await axios(newsURL);
        // Uncomment and handle sentiment response if needed
        // const sentimentResponse = await axios(sentimentURL);

        setStateValues({
          news: newsResponse.data.slice(0, 10),
          // bullishPct: sentimentResponse.data.sentiment?.bullishPercent || 0,
          // bearishPct: sentimentResponse.data.sentiment?.bearishPercent || 0,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [props.symbol]);

  return (
    <>
      <CardGroup
        itemsPerRow={1}
        stackable
        style={{
          marginTop: "1%",
          marginBottom: "0.2%",
          border: "none",
          boxShadow: "none",
        }}
      >
        {stateValues.news.length > 0 ? (
          stateValues.news.map((newsItem, index) => (
            <Card
              key={index}
              raised
              fluid
              style={{
                margin: "1%",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.05)";
                e.currentTarget.style.boxShadow =
                  "0 8px 16px rgba(0, 0, 0, 0.15)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.boxShadow =
                  "0 4px 8px rgba(0, 0, 0, 0.1)";
              }}
            >
              <CardContent>
                <Card.Header>
                  <a
                    href={newsItem.url}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.1em",
                      textDecoration: "none",
                    }}
                  >
                    {newsItem.title.slice(0, 100)}...
                  </a>
                </Card.Header>

                <Card.Description
                  style={{
                    fontSize: "0.95em",
                    color: "#333",
                    marginTop: "10px",
                  }}
                >
                  {newsItem.text}
                </Card.Description>
              </CardContent>
              <CardContent extra>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "0.85em",
                    color: "#777",
                  }}
                >
                  <span>{newsItem.site}</span>
                  <ReactTimeAgo date={newsItem.publishedDate} locale="en-US" />
                </div>
              </CardContent>
            </Card>
          ))
        ) : (
          <p>No news available</p>
        )}
      </CardGroup>
    </>
  );
}
