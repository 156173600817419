import React, { Component } from "react";
import { Grid, Header, Table, Segment } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";

export default class IntrinsicValue extends Component {
  render() {
    const { stockValues } = this.props;

    const segmentStyle = {
      padding: "20px",
      marginBottom: "20px",
      border: "1px solid #ddd",
      borderRadius: "5px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
    };

    const headerStyle = {
      color: "#333",
    };

    const valueStyle = {
      color: "#007bff",
      fontWeight: "bold",
      fontSize: "1.1.5em",
    };

    return (
      <Grid stackable>
        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
          <Grid.Column width={16}>
            <Header
              as="h2"
              textAlign="center"
              style={{ color: "#28a745" }}
              content="Intrinsic Value Analysis"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
          <Grid.Column width="16">
            <Segment style={segmentStyle}>
              <Header as="h3" style={headerStyle} textAlign="center">
                Intrinsic Value Inputs
              </Header>
              <Table striped celled compact>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell singleLine> Shares Outstanding</Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_number_long(
                        stockValues.compProfile.sharesOutstanding
                      )}
                    </Table.Cell>

                    <Table.Cell singleLine> Beta</Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {stockValues.compProfile.beta}
                    </Table.Cell>

                    <Table.Cell singleLine> Discount Rate Used</Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {stockValues.tenDCFiv.discRt}%
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell singleLine>
                      {" "}
                      Cash Flow Growth Rate (1 - 5) years
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_percent(
                        stockValues.tenDCFiv.growhthRate_1_5
                      )}
                    </Table.Cell>

                    <Table.Cell singleLine>
                      Cash Flow Growth Rate (5 - 10) years
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_percent(
                        stockValues.tenDCFiv.growhthRate_5_10
                      )}
                    </Table.Cell>

                    <Table.Cell singleLine>
                      Cash Flow Growth Rate (10 - 20) years
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_percent(
                        stockValues.tenDCFiv.growhthRate_10_20
                      )}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell singleLine>
                      {" "}
                      Total Debt (Short Term + Long Term)
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_currency_long(
                        stockValues.tenDCFiv.totalDebt
                      )}
                    </Table.Cell>

                    <Table.Cell singleLine> Debt per share (-)</Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_currency_usd(stockValues.tenDCFiv.dbtPrShr)}
                    </Table.Cell>
                    <Table.Cell />
                    <Table.Cell />
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell singleLine>
                      Operating Cash Flow/Net Income (Last 4 Quarters)
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_currency_long(
                        this.props.stockValues.tenDCFiv.amountTobeProjected
                      )}
                    </Table.Cell>
                    <Table.Cell singleLine>
                      {" "}
                      Cash and Short Term Investments
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_currency_long(
                        stockValues.tenDCFiv.totalCash
                      )}
                    </Table.Cell>

                    <Table.Cell singleLine>
                      {" "}
                      Available Cash per share (+)
                    </Table.Cell>
                    <Table.Cell singleLine style={valueStyle}>
                      {Utils.format_currency_usd(stockValues.tenDCFiv.cshPrShr)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 4 }}>
          <Grid.Column width="8">
            <Header
              as="h3"
              textAlign="center"
              style={{ color: "#28a745" }}
              content="Based on future Cashflows"
            />
            <Table basic="very" striped compact>
              <Table.Row>
                <Table.Cell>
                  <Segment style={segmentStyle}>
                    <Header as="h4" style={headerStyle} textAlign="center">
                      10 Year DCF Calculations
                    </Header>
                    <Table unstackable striped compact>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Present Value of future Cash Flows
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.tenDCFiv.pvOfFut
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Intrinsic Value before Cash/Debt
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.tenDCFiv.intrinsicValueBeforeCashDebt
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Discount to current Price
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_percent(
                              stockValues.tenDCFiv.underValueDiscount
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine> Intrinsic Value</Table.Cell>
                          <Table.Cell
                            singleLine
                            style={{
                              ...valueStyle,
                              color: "#28a745",
                              fontSize: "1.5em",
                            }}
                          >
                            {Utils.format_currency_usd(
                              stockValues.tenDCFiv.fnlIntrinVal
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Table.Cell>
                <Table.Cell>
                  <Segment style={segmentStyle}>
                    <Header as="h4" style={headerStyle} textAlign="center">
                      20 Year DCF Calculations
                    </Header>
                    <Table unstackable striped compact>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Present Value of future Cash Flows
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.twentyDCFiv.pvOfFut
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Intrinsic Value before Cash/Debt
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.twentyDCFiv
                                .intrinsicValueBeforeCashDebt
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Discount to current Price
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_percent(
                              stockValues.twentyDCFiv.underValueDiscount
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine> Intrinsic Value</Table.Cell>
                          <Table.Cell
                            singleLine
                            style={{
                              ...valueStyle,
                              color: "#28a745",
                              fontSize: "1.5em",
                            }}
                          >
                            {Utils.format_currency_usd(
                              stockValues.twentyDCFiv.fnlIntrinVal
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            </Table>
          </Grid.Column>

          <Grid.Column width="8">
            <Header
              as="h3"
              textAlign="center"
              style={{ color: "#28a745" }}
              content="Based on future Earnings"
            />
            <Table basic="very" striped compact>
              <Table.Row>
                <Table.Cell>
                  <Segment style={segmentStyle}>
                    <Header as="h4" style={headerStyle} textAlign="center">
                      10 Year Discounted Earnings Calculations
                    </Header>
                    <Table unstackable striped compact>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Present Value of future earnings
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.tenDEiv.pvOfFut
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Intrinsic Value before Cash/Debt
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.tenDEiv.intrinsicValueBeforeCashDebt
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Discount to current Price
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_percent(
                              stockValues.tenDEiv.underValueDiscount
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine> Intrinsic Value</Table.Cell>
                          <Table.Cell
                            singleLine
                            style={{
                              ...valueStyle,
                              color: "#28a745",
                              fontSize: "1.5em",
                            }}
                          >
                            {Utils.format_currency_usd(
                              stockValues.tenDEiv.fnlIntrinVal
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Table.Cell>
                <Table.Cell>
                  <Segment style={segmentStyle}>
                    <Header as="h4" style={headerStyle} textAlign="center">
                      20 Year Discounted Earnings Calculations
                    </Header>
                    <Table unstackable striped compact>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Present Value of future earnings
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.twentyDEiv.pvOfFut
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Intrinsic Value before Cash/Debt
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_currency_long(
                              stockValues.twentyDEiv
                                .intrinsicValueBeforeCashDebt
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine>
                            {" "}
                            Discount to current Price
                          </Table.Cell>
                          <Table.Cell singleLine style={valueStyle}>
                            {Utils.format_percent(
                              stockValues.twentyDEiv.underValueDiscount
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell singleLine> Intrinsic Value</Table.Cell>
                          <Table.Cell
                            singleLine
                            style={{
                              ...valueStyle,
                              color: "#28a745",
                              fontSize: "1.5em",
                            }}
                          >
                            {Utils.format_currency_usd(
                              stockValues.twentyDEiv.fnlIntrinVal
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                </Table.Cell>
              </Table.Row>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
