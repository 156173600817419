import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Container,
  Grid,
  Segment,
  Divider,
  List,
  Button,
  Modal,
} from "semantic-ui-react";
import "../css/home.css";
import "../css/index.css";

export default class Footer extends Component {
  state = {
    modalOpen: false,
    aboutUsModalOpen: false,
    privacyModalOpen: false,
  };

  handleModalToggle = () =>
    this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));

  handleAboutUsModalToggle = () =>
    this.setState((prevState) => ({
      aboutUsModalOpen: !prevState.aboutUsModalOpen,
    }));

  handlePrivacyModalToggle = () =>
    this.setState((prevState) => ({
      privacyModalOpen: !prevState.privacyModalOpen,
    }));

  render() {
    return (
      <Segment
        inverted
        style={{
          borderRadius: 0,
          padding: "2rem 1rem", // Reduced padding
          backgroundColor: "#36454F",
          margin: "0", // Remove default segment margin
        }}
      >
        <Container>
          <Grid stackable>
            <Grid.Row columns={3}>
              <Grid.Column textAlign="center">
                <List link inverted>
                  <List.Item
                    as="a"
                    style={{ color: "#B0C4DE", fontSize: "1.1rem" }} // Increased font size
                    onClick={this.handleAboutUsModalToggle}
                  >
                    About Us
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <List link inverted>
                  <List.Item
                    as="a"
                    onClick={this.handleModalToggle}
                    style={{ color: "#B0C4DE", fontSize: "1.1rem" }} // Increased font size
                  >
                    Terms and Conditions
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <List link inverted>
                  <List.Item
                    as="a"
                    onClick={this.handlePrivacyModalToggle}
                    style={{ color: "#B0C4DE", fontSize: "1.1rem" }} // Increased font size
                  >
                    Privacy Policy
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Divider inverted style={{ borderColor: "#555" }} />
                <p
                  style={{
                    textAlign: "left",
                    color: "#B0C4DE",
                    lineHeight: "1.6",
                    fontSize: "1rem", // Increased font size
                  }}
                >
                  <strong>SimplyStock.net</strong> is committed to providing
                  accessible and user-friendly stock analysis. However, it is
                  important to clarify that SimplyStock.net does not act as a
                  stockbroker, dealer, or investment advisor. The information
                  provided on the website is for informational purposes only and
                  should not be construed as financial advice or as a
                  recommendation to buy or sell any particular securities.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        {/* About Us Modal */}
        <Modal
          open={this.state.aboutUsModalOpen}
          onClose={this.handleAboutUsModalToggle}
          size="large"
          closeIcon
          dimmer="blurring"
        >
          <Modal.Header style={{ backgroundColor: "#4A6572", color: "white" }}>
            About Us
          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "#ECEFF1", color: "#333" }}>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                padding: "1.5rem",
              }} // Adjusted padding
            >
              <h3 style={{ fontSize: "1.4rem" }}>
                Our Mission: Empowering Informed Investors
              </h3>
              <p style={{ fontSize: "1.1rem" }}>
                At SimplyStock.net, we believe that everyone should have access
                to clear and accurate financial data. Founded by a team of
                experienced financial analysts and technology experts, we are
                driven by a mission to demystify the stock market. We deliver
                robust tools, real-time data, and insightful analysis, enabling
                our users to navigate the complexities of investing with
                confidence. Our commitment lies in providing a user-friendly
                platform that supports your financial journey.
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "#ECEFF1" }}>
            <Button onClick={this.handleAboutUsModalToggle}>Close</Button>
          </Modal.Actions>
        </Modal>

        {/* Terms and Conditions Modal (Existing Modal) */}
        <Modal
          open={this.state.modalOpen}
          onClose={this.handleModalToggle}
          size="large"
          closeIcon
          dimmer="blurring"
        >
          <Modal.Header style={{ backgroundColor: "#4A6572", color: "white" }}>
            Terms and Conditions
          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "#ECEFF1", color: "#333" }}>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                padding: "1.5rem",
              }} // Adjusted padding
              dangerouslySetInnerHTML={{
                __html: `<h4>1. General Information</h4>
                <p style={{ fontSize: "1.1rem" }}>The content on SimplyStock.net is provided for informational purposes only and does not constitute financial or investment advice. We do not recommend the purchase or sale of any securities or stocks. All statements, analyses, and opinions are based on general information and should not be interpreted as personalized advice.</p>
                <h4>2. Independent Research</h4>
                <p style={{ fontSize: "1.1rem" }}>Users are encouraged to conduct their own research before making any investment decisions. SimplyStock is not responsible for any financial decisions made based on the content provided on this Website.</p>
                <h4>3. Accuracy and Disclaimer</h4>
                <p style={{ fontSize: "1.1rem" }}>While we make efforts to provide accurate and up-to-date information, SimplyStock does not guarantee the accuracy, completeness, or timeliness of the content on this Website. The content is provided "as is," and users bear all risks associated with reliance on it. We are not liable for any losses or damages resulting from the use of the Website.</p>
                <h4>4. Intellectual Property</h4>
                <p style={{ fontSize: "1.1rem" }}>All content on SimplyStock.net, including text, graphics, logos, and other materials, is the property of SimplyStock or its licensors. You may not republish, reproduce, distribute, or sell any content from this Website without written permission. Unauthorized sharing or distribution of content is prohibited.</p>
                <h4>5. Data Sources and Validation</h4>
                <p style={{ fontSize: "1.1rem" }}>The data on SimplyStock.net is sourced from third-party providers. While we strive to validate this data, there may be occasional inaccuracies or outdated information. We recommend verifying data with other trusted financial websites.</p>
                <h4>6. Data Refresh Rates</h4>
                <p style={{ fontSize: "1.1rem" }}>Financial data on the Website is refreshed periodically, such as annually or quarterly. The data displayed may differ from other financial websites due to variations in data update schedules.</p>
                <h4>7. Stock Coverage</h4>
                <p style={{ fontSize: "1.1rem" }}>SimplyStock does not provide analysis for all U.S. stocks. Currently, we only cover a limited set of companies, and do not provide data on newly public companies or mutual funds.</p>
                <h4>8. Termination of Access</h4>
                <p style={{ fontSize: "1.1rem" }}>We reserve the right to suspend, revoke, or terminate user access to the Website at our discretion, without prior notice, if any of these Terms are violated.</p>
                <h4>9. Limitation of Liability</h4>
                <p style={{ fontSize: "1.1rem" }}>SimplyStock and its affiliates are not responsible for any direct, indirect, incidental, or consequential damages arising from the use of this Website. Users use the Website at their own risk, and the Website is provided "as is."</p>
                <h4>10. Indemnification</h4>
                <p style={{ fontSize: "1.1rem" }}>By using this Website, you agree to indemnify and hold harmless SimplyStock and its affiliates from any claims, damages, or expenses arising from your use of the Website or violation of these Terms.</p>
                <h4>11. Changes to Terms</h4>
                <p style={{ fontSize: "1.1rem" }}>We may update or modify these Terms at any time. It is your responsibility to review these Terms periodically. By continuing to use the Website, you agree to any updates made to the Terms.</p>`,
              }}
            />
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "#ECEFF1" }}>
            <Button onClick={this.handleModalToggle}>Close</Button>
          </Modal.Actions>
        </Modal>

        {/* Privacy Policy Modal */}
        <Modal
          open={this.state.privacyModalOpen}
          onClose={this.handlePrivacyModalToggle}
          size="large"
          closeIcon
          dimmer="blurring"
        >
          <Modal.Header style={{ backgroundColor: "#4A6572", color: "white" }}>
            Privacy Policy
          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "#ECEFF1", color: "#333" }}>
            <div
              style={{
                maxHeight: "500px",
                overflowY: "auto",
                padding: "1.5rem",
              }} // Adjusted padding
            >
              <p style={{ fontSize: "1.1rem" }}>
                At SimplyStock.net, we are committed to protecting your privacy.
                This Privacy Policy outlines how we collect, use, and safeguard
                your information.
              </p>
              <h4 style={{ fontSize: "1.2rem" }}>Information We Collect</h4>
              <p style={{ fontSize: "1.1rem" }}>
                We may collect information you provide directly to us, such as
                your name and email address, when you register or subscribe to
                our services. We also collect data related to your usage of our
                platform, including IP addresses and browsing behavior, to
                improve our services and user experience.
              </p>
              <h4 style={{ fontSize: "1.2rem" }}>
                How We Use Your Information
              </h4>
              <p style={{ fontSize: "1.1rem" }}>
                Your information is used to provide and improve our services,
                personalize your experience, and communicate with you about
                updates and offers. We do not sell your personal information to
                third parties.
              </p>
              <h4 style={{ fontSize: "1.2rem" }}>Data Security</h4>
              <p style={{ fontSize: "1.1rem" }}>
                We implement robust security measures to protect your
                information from unauthorized access, alteration, or
                destruction.
              </p>
              <h4 style={{ fontSize: "1.2rem" }}>Changes to This Policy</h4>
              <p style={{ fontSize: "1.1rem" }}>
                We may update this Privacy Policy from time to time. We will
                notify you of any significant changes by posting the new policy
                on our website.
              </p>
              <h4 style={{ fontSize: "1.2rem" }}>Contact Us</h4>
              <p style={{ fontSize: "1.1rem" }}>
                If you have any questions about this Privacy Policy, please
                contact us at info@SimplyStock.net.
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "#ECEFF1" }}>
            <Button onClick={this.handlePrivacyModalToggle}>Close</Button>
          </Modal.Actions>
        </Modal>
      </Segment>
    );
  }
}
