import React from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import withWindowSize from "react-window-size";

const StockChart = (props) => {
  const isMobile = props.windowWidth < 768;
  const chartWidth = isMobile ? props.windowWidth - 40 : 600; // Adjust for mobile padding
  const chartHeight = 330; // Adjust height for mobile/desktop

  return (
    <AdvancedRealTimeChart
      symbol={props.symbol}
      allow_symbol_change={false}
      hide_top_toolbar={true}
      range={"3M"}
      interval="D"
      style="2"
      enable_publishing={false}
      hide_legend={true}
      studies={[
        {
          id: "MASimple@tv-basicstudies",
          version: 60,
          inputs: {
            length: 50,
          },
        },
        {
          id: "MASimple@tv-basicstudies",
          version: 60,
          inputs: {
            length: 100,
          },
        },
        {
          id: "MASimple@tv-basicstudies",
          version: 60,
          inputs: {
            length: 200,
          },
        },
      ]}
      studies_overrides={[{ "moving average.length": 50 }]}
      width={chartWidth - 50}
      height={chartHeight}
      hide_side_toolbar={true}
      locale="en" // Ensure consistent locale
    />
  );
};

export default withWindowSize(StockChart);
