import React, { useState } from "react";
import {
  Header,
  Image,
  Label,
  Card,
  Modal,
  Button,
  Grid,
  List,
  Divider,
} from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import StockRadarChart from "./StockRadarChart";
import Utils from "../util/Utils";
import CompanyPage from "../company/CompanyPage";

export default function CompareResults({ stckLst }) {
  const [stateValues, setstateValues] = useState({ symbol: "", open: false });

  // State to manage visibility of all details at once
  const [showAllDetails, setShowAllDetails] = useState(false);

  const toggleAllDetails = () => {
    setShowAllDetails((prevState) => !prevState);
  };

  const showModal = (symbol) => {
    setstateValues({ symbol, open: true });
  };

  return (
    <>
      {/* Main Stock Comparison Grid */}
      <Grid stackable centered>
        <Grid.Row columns={1} style={{ width: "100%" }}>
          {/* Show All Details Button */}
          <Grid.Column
            width={16}
            style={{ textAlign: "center", marginBottom: "20px" }}
          >
            <Button
              size="large"
              color={showAllDetails ? "red" : "blue"}
              onClick={toggleAllDetails}
            >
              {showAllDetails ? "Show Less Details" : "Show All Details"}
            </Button>
          </Grid.Column>

          {stckLst.map((stock) => (
            <Grid.Column
              key={stock.symbol}
              mobile={16}
              tablet={8}
              computer={3}
              style={{ marginBottom: "20px" }}
            >
              {/* Combined Stock Card */}
              <Card
                raised
                style={{ backgroundColor: "#F5F5F5", borderRadius: "10px" }}
              >
                <Card.Content>
                  <Header as="h4" textAlign="center" color="blue">
                    <Image
                      src={stock.image}
                      rounded
                      style={{
                        maxWidth: "60px",
                        marginBottom: "2px",
                        height: "auto",
                      }}
                    />
                    {stock.symbol}
                  </Header>
                  <Card.Description textAlign="center">
                    <strong>{stock.companyName}</strong>
                  </Card.Description>
                  <Label
                    size="small"
                    className={Utils.getRecommendationColor(
                      stock.recommendation
                    )}
                    style={{
                      marginTop: "5px",
                      display: "block",
                      textAlign: "center",
                      fontSize: "1.1em",
                    }}
                  >
                    {stock.recommendation}
                  </Label>
                </Card.Content>

                {/* Stock Snapshot (Radar Chart) */}
                <Card.Content>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <StockRadarChart
                      scoreData={stock}
                      width="{80%}"
                      height={100}
                      backgroundColor="#F9F8F6"
                      labelSize="short"
                    />
                  </div>
                </Card.Content>

                {/* Show Details Based on Global State */}
                {showAllDetails && (
                  <Card.Content>
                    {/* Performance Section */}
                    <Header as="h4" textAlign="center" color="blue">
                      Performance
                    </Header>
                    <List style={{ fontSize: "1.1em", marginBottom: "20px" }}>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>1 Day Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfDay, 0, 1)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>1 Week Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfWeek, 0, 1)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>1 Month Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfMonth, 0, 1)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>1 Year Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfYear, 0, 1)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>YTD Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfYTD, 0, 1)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>5Y Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfYTD5, 0, 1)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>10Y Performance:</strong>{" "}
                        {Utils.format_ratio(stock.perfYTD10, 0, 1)}
                      </List.Item>
                    </List>

                    <Divider />

                    {/* Financials Section */}
                    <Header as="h4" textAlign="center" color="blue">
                      Financials
                    </Header>
                    <List style={{ fontSize: "1.1em", marginBottom: "20px" }}>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Market Cap:</strong>{" "}
                        {Utils.format_currency_short(stock.marketCap)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Price:</strong>{" "}
                        {Utils.format_currency_usd(stock.price)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Fair Value:</strong>{" "}
                        {Utils.format_currency_usd(stock.intrinsicValue)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Dividend Yield:</strong>{" "}
                        {Utils.format_ratio(stock.dividendYield, 4, 0)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>PE:</strong>{" "}
                        {Utils.format_none(stock.pe, 15, 0)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>PEG:</strong>{" "}
                        {Utils.format_number(stock.pegRatio, 1.5, 0)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>ROE:</strong>{" "}
                        {Utils.format_none(stock.roe, 12, 1)}%
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Gross Margin:</strong>{" "}
                        {Utils.format_ratio(stock.grossMargin, 50, 1)}%
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Profit Margin:</strong>{" "}
                        {Utils.format_ratio(stock.profitMargin, 25, 1)}%
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Debt to Equity:</strong>{" "}
                        {Utils.format_none(stock.debtEq, 1, 0)}
                      </List.Item>
                    </List>

                    <Divider />

                    {/* Valuation Section */}
                    <Header
                      as="h4"
                      textAlign="center"
                      color="blue"
                      style={{ marginBottom: "10px" }}
                    >
                      Valuation
                    </Header>
                    <List style={{ fontSize: "1.1em" }}>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Price to Earnings (P/E):</strong>{" "}
                        {Utils.format_none(stock.pe, 15, 0)}
                      </List.Item>
                      <List.Item style={{ marginBottom: "10px" }}>
                        <strong>Price to Earnings Growth (PEG):</strong>{" "}
                        {Utils.format_number(stock.pegRatio, 1.5, 0)}
                      </List.Item>
                    </List>
                  </Card.Content>
                )}
              </Card>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>

      {/* Modal for Detailed Stock Information */}
      <Modal
        closeOnEscape={true}
        centered={true}
        open={stateValues.open}
        size="large"
        style={{ marginLeft: "2%" }}
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <CompanyPage symbol={stateValues.symbol} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className="ui blue button"
            onClick={() => setstateValues({ ...stateValues, open: false })}
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
