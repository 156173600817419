import React, { Component } from "react";
import { Accordion, Icon, Table } from "semantic-ui-react";
import Utils from "../util/Utils";

export default class IntrsicAccVal extends Component {
  state = { activeIndex: -1 };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  loadAmounts = function (title, ai, index, amounts) {
    return (
      <>
        <Accordion.Title
          active={ai === index}
          index={index}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" /> {title}{" "}
        </Accordion.Title>
        <Accordion.Content active={ai === index}>
          <Table unstackable size="small" compact="very" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Projected Amount
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Discount Factor
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Discount Value
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(amounts).map((prjObj) => (
                <Table.Row key={amounts[prjObj].year}>
                  <Table.Cell textAlign="center">
                    {amounts[prjObj].year}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {Utils.format_currency_usd(amounts[prjObj].projectedAmount)}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {amounts[prjObj].discFact}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {Utils.format_currency_usd(amounts[prjObj].discVal)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Accordion.Content>
      </>
    );
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <Accordion>
        {this.loadAmounts(
          " 10 Year Discounted Cashflow Projected Amounts",
          activeIndex,
          0,
          this.props.stockValues.tenDCFiv.projectedAmtList
        )}
        {this.loadAmounts(
          " 20 Year Discounted Cashflow Projected Amounts",
          activeIndex,
          1,
          this.props.stockValues.twentyDCFiv.projectedAmtList
        )}
        {this.loadAmounts(
          " 10 Year Discounted Earnings Projected Amounts",
          activeIndex,
          2,
          this.props.stockValues.tenDEiv.projectedAmtList
        )}
        {this.loadAmounts(
          " 20 Year Discounted Earnings Projected Amounts",
          activeIndex,
          3,
          this.props.stockValues.twentyDEiv.projectedAmtList
        )}
      </Accordion>
    );
  }
}
