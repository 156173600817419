import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";
import { Header, Table, Icon } from "semantic-ui-react";
import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Constants from "../util/Constants";

export default function CompanyRecommendations(props) {
  const initialRecommendations = {
    buy: 0,
    strongBuy: 0,
    hold: 0,
    sell: 0,
    strongSell: 0,
  };

  const [recommendations, setRecommendations] = useState(
    initialRecommendations
  );

  useEffect(() => {
    let url = Constants.RECOMMENDATIONS_URL + "&symbol=" + props.symbol;

    fetch(url)
      .then((response) => response.json())
      .then((recommendationsData) => {
        if (recommendationsData.length > 0)
          setRecommendations(recommendationsData[0]);
      })
      .catch(function (error) {
        console.log(
          "CompanyRecommendations:stock app response Error:",
          error.stack
        );
      });

    return () => {};
  }, [props]);

  const data = [
    {
      name: "Buy",
      value: recommendations.strongBuy + recommendations.buy,
    },
    { name: "Hold", value: recommendations.hold },
    {
      name: "Sell",
      value: recommendations.sell + recommendations.strongSell,
    },
  ];
  const COLORS = ["#52D017", "#FFE562", "#DA110D"];

  return (
    <>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.Cell
              colSpan="2"
              style={{
                backgroundColor: "#C4C6C782",
              }}
            >
              <Header as="h4" textAlign="center">
                <Icon name="thumbs up" siz="mini" />
                {recommendations.buy +
                  recommendations.strongBuy +
                  recommendations.hold +
                  recommendations.strongSell +
                  recommendations.sell}{" "}
                Analysts Recommendations
              </Header>
            </Table.Cell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <PieChart width={props.width} height={props.height}>
                <Pie
                  data={data}
                  cx={"45%"}
                  cy={"70%"}
                  startAngle={180}
                  endAngle={0}
                  innerRadius={50}
                  outerRadius={80}
                  fill="#8884d8"
                  label={({
                    cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    value,
                    index,
                  }) => {
                    const RADIAN = Math.PI / 180;
                    // eslint-disable-next-line
                    const radius =
                      25 + innerRadius + (outerRadius - innerRadius);
                    // eslint-disable-next-line
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    // eslint-disable-next-line
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <text
                        x={x}
                        y={y}
                        fontSize={12}
                        fill="#8884d8"
                        textAnchor={x > cx ? "start" : "end"}
                        dominantBaseline="central"
                      >
                        {data[index].name} ({value})
                      </text>
                    );
                  }}
                  paddingAngle={4}
                  dataKey="value"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
