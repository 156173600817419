import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Grid, Image, List, Segment, Header } from "semantic-ui-react";
import "../css/home.css";
import Constants from "../util/Constants";
import ReactTimeAgo from "react-time-ago";
import StockChart from "../company/TradingViewChart";

TimeAgo.addDefaultLocale(en);

export default function MainNews(props) {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const newsResponse = await axios(Constants.NEWS_URL);
        setNews(newsResponse.data.slice(0, 15)); // fetching the first 15 news items
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchData();
  }, [props]);

  return (
    <Segment style={{ padding: "2rem", margin: "0" }}>
      <Grid stackable>
        {/* Header for Stock Charts */}
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header as="h3" textAlign="center">
              Stock Market Overview
            </Header>
          </Grid.Column>
        </Grid.Row>

        {/* Stock Charts */}
        <Grid.Row columns={3} tablet={2} computer={3}>
          <Grid.Column>
            <StockChart symbol={"DJI"} />
          </Grid.Column>
          <Grid.Column>
            <StockChart symbol={"FOREXCOM:SPX500"} />
          </Grid.Column>
          <Grid.Column>
            <StockChart symbol={"NASDAQ:IXIC"} />
          </Grid.Column>
        </Grid.Row>

        {/* News Section */}
        <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 6 }}>
          <Grid.Column>
            <List divided relaxed style={{ marginTop: "1rem" }}>
              {news.map((item) => (
                <List.Item
                  key={item.uuid}
                  style={{
                    padding: "1rem 0",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <Image
                    src={item.image}
                    style={{
                      width: "60px", // Adjust width as needed
                      height: "40px", // Adjust height as needed
                      marginRight: "1.2rem",
                      objectFit: "cover", // Ensure image covers the area
                      borderRadius: "4px", // Optional: Add slight rounding
                      flexShrink: 0,
                    }}
                  />
                  <List.Content style={{ flex: 1, minWidth: 0 }}>
                    <List.Header
                      as="a"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "600",
                        color: "#333",
                        lineHeight: "1.5",
                        marginBottom: "0.5rem",
                      }}
                    >
                      {item.title}
                    </List.Header>
                    <List.Description
                      style={{ fontSize: "1rem", color: "#777" }}
                    >
                      <ReactTimeAgo date={item.publishedDate} locale="en-US" />
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
