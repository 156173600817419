import React from "react";
import { Grid, Card, Table, Popup } from "semantic-ui-react";
import Utils from "../util/Utils";

const SectorGainersLosers = ({ gainersLosers, onClickSymbolFunction }) => {
  const renderStockTable = (list, isGainer) => {
    return (
      <Table celled compact="very" basic="very" unstackable>
        <Table.Body>
          {list &&
            Object.keys(list).map((index) => {
              const stock = list[index];

              if (
                !stock ||
                (isGainer && stock.changesPercentage <= 0) ||
                (!isGainer && stock.changesPercentage >= 0)
              ) {
                return null;
              }

              return (
                <Table.Row key={stock.symbol}>
                  <Table.Cell>
                    <Popup
                      trigger={
                        <div
                          onClick={() => onClickSymbolFunction(stock.symbol)}
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ fontWeight: "bold" }}>
                            {stock.symbol}
                          </span>{" "}
                        </div>
                      }
                      content={
                        <>
                          <Popup.Header>{stock.name}</Popup.Header>
                          <Popup.Content>
                            <p>{Utils.format_currency_usd(stock.price)}</p>
                            <p>
                              {Utils.format_currency_usd(stock.change)} (
                              {stock.changesPercentage}%)
                            </p>
                          </Popup.Content>
                        </>
                      }
                    />
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <span
                      style={{
                        color: isGainer ? "#4caf50" : "#f44336",
                        fontWeight: "bold",
                      }}
                    >
                      {Utils.format_number(stock.changesPercentage)}%
                    </span>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  };

  const renderCategory = (name, list, isGainer) => (
    <Card fluid style={{ height: "100%" }}>
      <Card.Header
        textAlign="center"
        style={{
          height: "40px",
          background: isGainer ? "#66bb6a" : "#e57373",
          color: "white",
          padding: "0.5em",
          fontSize: "16px",
        }}
      >
        {name}
      </Card.Header>
      <Card.Content style={{ padding: "0.5em" }}>
        {renderStockTable(list, isGainer)}
      </Card.Content>
    </Card>
  );

  const renderCategoryGroup = (categories, isGainer) => {
    const columns = categories.map(({ name, list }) => (
      <Grid.Column
        key={name}
        mobile={8} // 4 cards per row on mobile (3 cards max)
        tablet={4} // 4 cards per row on tablet (3 cards max)
        computer={4} // 4 cards per row on desktop
        style={{ padding: "1em" }}
      >
        {renderCategory(name, list, isGainer)}
      </Grid.Column>
    ));

    return (
      <Grid>
        <Grid.Row style={{ padding: "1em 0" }}>{columns}</Grid.Row>
      </Grid>
    );
  };

  const gainersCategories = [
    {
      name: "Technology",
      list: gainersLosers?.sectorGainers?.Technology,
    },
    {
      name: "Basic Materials",
      list: gainersLosers?.sectorGainers?.["Basic Materials"],
    },
    {
      name: "Comm Services",
      list: gainersLosers?.sectorGainers?.["Communication Services"],
    },
    {
      name: "Consumer Cyclical",
      list: gainersLosers?.sectorGainers?.["Consumer Cyclical"],
    },
    {
      name: "Consumer Defensive",
      list: gainersLosers?.sectorGainers?.["Consumer Defensive"],
    },
    { name: "Energy", list: gainersLosers?.sectorGainers?.Energy },
    {
      name: "Financial",
      list: gainersLosers?.sectorGainers?.Financial,
    },
    {
      name: "Healthcare",
      list: gainersLosers?.sectorGainers?.Healthcare,
    },
    {
      name: "Industrials",
      list: gainersLosers?.sectorGainers?.Industrials,
    },
    {
      name: "Real Estate",
      list: gainersLosers?.sectorGainers?.["Real Estate"],
    },
    {
      name: "Utilities",
      list: gainersLosers?.sectorGainers?.Utilities,
    },
  ];

  const losersCategories = [
    {
      name: "Technology",
      list: gainersLosers?.sectorLosers?.Technology,
    },
    {
      name: "Basic Materials",
      list: gainersLosers?.sectorLosers?.["Basic Materials"],
    },
    {
      name: "Comm Services",
      list: gainersLosers?.sectorLosers?.["Communication Services"],
    },
    {
      name: "Consumer Cyclical",
      list: gainersLosers?.sectorLosers?.["Consumer Cyclical"],
    },
    {
      name: "Consumer Defensive",
      list: gainersLosers?.sectorLosers?.["Consumer Defensive"],
    },
    { name: "Energy", list: gainersLosers?.sectorLosers?.Energy },
    { name: "Financial", list: gainersLosers?.sectorLosers?.Financial },
    {
      name: "Healthcare",
      list: gainersLosers?.sectorLosers?.Healthcare,
    },
    {
      name: "Industrials",
      list: gainersLosers?.sectorLosers?.Industrials,
    },
    {
      name: "Real Estate",
      list: gainersLosers?.sectorLosers?.["Real Estate"],
    },
    { name: "Utilities", list: gainersLosers?.sectorLosers?.Utilities },
  ];

  return (
    <Grid style={{ padding: "1em" }} stackable>
      <Grid.Row>
        {/* Gainers Section */}
        <Grid.Column width={8}>
          <Card.Header
            textAlign="center"
            style={{
              background: "#66bb6a",
              color: "white",
              fontSize: "20px",
              padding: "1em",
            }}
          >
            Sector Gainers
          </Card.Header>
          {gainersLosers && renderCategoryGroup(gainersCategories, true)}
        </Grid.Column>

        {/* Losers Section */}
        <Grid.Column width={8}>
          <Card.Header
            textAlign="center"
            style={{
              background: "#e57373",
              color: "white",
              fontSize: "20px",
              padding: "1em",
            }}
          >
            Sector Losers
          </Card.Header>
          {gainersLosers && renderCategoryGroup(losersCategories, false)}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SectorGainersLosers;
