import React, { Component } from "react";
import {
  Card,
  Grid,
  Header,
  Table,
  Image,
  Modal,
  Button,
  Icon,
} from "semantic-ui-react";

import "../../node_modules/react-vis/dist/style.css";
import "../css/home.css";
import Utils from "../util/Utils";
import StockChart from "./StockChart";

import Constants, { API_KEYS } from "../util/Constants";
import axios from "axios";
import CompanyPage from "./CompanyPage";

export default class ETFProfile extends Component {
  state = {
    holdingsData: [],
    sectorChartData: [],
    countryData: [],
    open: false,
    symbol: "",
  };

  showModal = (symbol) => {
    this.setState({
      symbol: symbol,
      open: true,
    });
  };

  setOpen = (flag) => {
    this.setState({
      open: flag,
    });
  };

  async componentDidMount() {
    let holdingsUrl =
      Constants.ETF_HOLDINGS_URL +
      this.props.stockValues.compProfile.symbol +
      "?" +
      API_KEYS.FMP_API_KEY;

    let sectorUrl =
      Constants.ETF_SECTOR_URL +
      this.props.stockValues.compProfile.symbol +
      "?" +
      API_KEYS.FMP_API_KEY;

    let countryUrl =
      Constants.ETF_COUNTRY_URL +
      this.props.stockValues.compProfile.symbol +
      "?" +
      API_KEYS.FMP_API_KEY;

    const [holdingsResponse, sectorResponse, countryResponse] =
      await Promise.all([
        axios.get(holdingsUrl),
        axios.get(sectorUrl),
        axios.get(countryUrl),
      ]);

    let sectorChartData = sectorResponse.data.map((d) => {
      return {
        name: d.sector,
        value: Number(d.weightPercentage.replace("%", "")),
      };
    });
    sectorChartData.sort((a, b) => b.value - a.value);

    let holdingsData = holdingsResponse.data.slice(0, 15);

    holdingsData.sort((a, b) => {
      return b.weightPercentage - a.weightPercentage;
    });

    this.setState({
      holdingsData,
      sectorChartData: sectorResponse.data,
      countryData: countryResponse.data,
    });
  }

  render = () => {
    return (
      <>
        <Grid stackable>
          <Grid.Row columns={{ mobile: 1, tablet: 2, computer: 2 }}>
            <Grid.Column width={6}>
              <Card fluid raised>
                <Card.Content>
                  <Card.Header textAlign="center">
                    About {this.props.stockValues.compProfile.symbol}
                  </Card.Header>
                  <Card.Description
                    style={{ fontSize: "1.3em", fontFamily: "Calibri" }}
                  >
                    {this.props.stockValues.compProfile.description}
                  </Card.Description>
                </Card.Content>
              </Card>
              <StockChart symbol={this.props.stockValues.compProfile.symbol} />
            </Grid.Column>
            <Grid.Column width={3}>
              <Card fluid raised>
                <Table singleLine definition compact="very" celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell
                        colSpan="2"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        <Header as="h4" textAlign="center">
                          <Icon name="info circle" />
                          Key Information
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Exchange</Table.Cell>
                      <Table.Cell>
                        {this.props.stockValues.compProfile.exchangeShortName}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Market Cap</Table.Cell>
                      <Table.Cell>
                        {Utils.getCompanySize(
                          this.props.stockValues.compProfile.marketCap
                        )}{" "}
                        (
                        {Utils.format_currency_short(
                          this.props.stockValues.compProfile.marketCap
                        )}
                        )
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Average Volume</Table.Cell>
                      <Table.Cell>
                        {Utils.format_number(
                          this.props.stockValues.compProfile.avgVolume
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Dividend</Table.Cell>
                      <Table.Cell>
                        <Header as="h5">
                          {Utils.format_currency_usd(
                            this.props.stockValues.compProfile.lastDiv
                          )}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Dividend Yield</Table.Cell>
                      <Table.Cell>
                        <Header as="h5">
                          {this.props.stockValues.compProfile.dividendYield}%
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>ETF Created Date</Table.Cell>
                      <Table.Cell>
                        <Header as="h5">
                          {this.props.stockValues.compProfile.ipoDate
                            ? this.props.stockValues.compProfile.ipoDate.slice(
                                0,
                                10
                              )
                            : null}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
              <Card fluid raised>
                <Table singleLine definition compact="very" celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.Cell
                        colSpan="2"
                        style={{ backgroundColor: "#E0E0E0" }}
                      >
                        <Header as="h4" textAlign="center">
                          <Icon name="sliders" />
                          ETF Performance
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>1 Day</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfDay,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>1 Week</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfWeek,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>1 Month </Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfMonth,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>YTD</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfYTD,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>1 Year </Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfYear,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>5 Year</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfYTD5,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>10 Year</Table.Cell>
                      {Utils.TableCell(
                        this.props.stockValues.stockReport.perfYTD10,
                        Utils.format_ratio,
                        0,
                        1
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Column>
            <Grid.Column width={3}>
              {this.state.sectorChartData.length > 0 && (
                <Card fluid raised>
                  <Table
                    singleLine
                    definition
                    compact="very"
                    celled
                    style={{ margin: "0 auto" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell
                          colSpan="2"
                          style={{ backgroundColor: "#E0E0E0" }}
                        >
                          <Header as="h4" textAlign="center">
                            <Icon name="chart pie" />
                            Sector Allocation
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Object.keys(this.state.sectorChartData).map((index) => (
                        <Table.Row
                          key={this.state.sectorChartData[index].sector}
                        >
                          <Table.Cell>
                            {this.state.sectorChartData[index].sector}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {this.state.sectorChartData[index]
                              .weightPercentage === 10000
                              ? "N/A"
                              : this.state.sectorChartData[
                                  index
                                ].weightPercentage.slice(0, 4)}
                            {this.state.sectorChartData[
                              index
                            ].weightPercentage.charAt(
                              this.state.sectorChartData[index].weightPercentage
                                .length - 1
                            )}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Card>
              )}
              {this.state.countryData.length > 0 && (
                <Card fluid raised>
                  <Table
                    singleLine
                    definition
                    compact="very"
                    celled
                    style={{ margin: "0 auto" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell
                          colSpan="2"
                          style={{ backgroundColor: "#E0E0E0" }}
                        >
                          <Header as="h4" textAlign="center">
                            <Icon name="flag outline" />
                            Country Weightings
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Object.keys(this.state.countryData).map((index) => (
                        <Table.Row key={this.state.countryData[index].country}>
                          <Table.Cell>
                            {this.state.countryData[index].country}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {this.state.countryData[index].weightPercentage ===
                            10000
                              ? "N/A"
                              : this.state.countryData[index].weightPercentage}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Card>
              )}
            </Grid.Column>
            <Grid.Column width={3}>
              {this.state.holdingsData.length > 0 && (
                <Card fluid raised>
                  <Table definition singleLine compact="very" celled collapsing>
                    <Table.Header>
                      <Table.Row>
                        <Table.Cell
                          colSpan="2"
                          style={{ backgroundColor: "#E0E0E0" }}
                        >
                          <Header as="h4" textAlign="center">
                            <Icon name="sliders" />
                            Top 15 Holdings
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Object.keys(this.state.holdingsData).map((index) => (
                        <Table.Row
                          key={this.state.holdingsData[index].asset}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.showModal(this.state.holdingsData[index].asset)
                          }
                        >
                          <Table.Cell textAlign="left">
                            {this.state.holdingsData[index].asset !== null ? (
                              <Header as="h6" image>
                                <Image
                                  src={
                                    Constants.IMAGE_URL +
                                    this.state.holdingsData[index].asset +
                                    ".jpg"
                                  }
                                  rounded
                                  style={{ mixBlendMode: "multiply" }}
                                />
                              </Header>
                            ) : (
                              "-"
                            )}
                            {this.state.holdingsData[index].asset}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {this.state.holdingsData[index].weightPercentage}%
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Card>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          closeOnEscape={true}
          centered={true}
          open={this.state.open}
          size="fullscreen"
        >
          <Modal.Content scrolling>
            <Modal.Description>
              <CompanyPage symbol={this.state.symbol} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="ui blue button"
              onClick={() => this.setOpen(false)}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  };
}
